import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image';
import LocalizedLink from '../LocalizedLink';

export const BannerWrapper = styled.div`
  min-height: 200px;
  display: flex;
  align-items: center;
  padding: var(--space-xxl) 0;
  position: relative;
  overflow:hidden;
  
  ${media.greaterThan('small')`
    min-height: 300px;
    
  `}
  ${media.greaterThan('medium')`
    min-height: 400px;
  `}
  ${media.greaterThan('large')`
    min-height: 500px;
  `}
  ${media.greaterThan('huge')`
    min-height: 600px;
  `}      
  ${media.lessThan('medium')`
    background-color: var(--color-white);
    padding: var(--space-xl) 0;
  `}  

`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-width: var(--width-container);
  margin: 0 auto;
  align-items: center;
  justify-content: flex-start;
  padding: 0 var(--space);
  ${media.greaterThan('large')`
    padding: 0 var(--space-sm);
  `}

`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height:100%;
  top:0;
  z-index: -1;
  background-color: var(--color-white);
`;

export const BackgroundImage = styled(Img)`
  min-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);  
  ${media.lessThan('medium')`
    display: none;
  `}  
`;

export const TrophyImage = styled.img`
  margin-top: 6px;
  margin-right: 6px;
  width: 44px;
  ${media.lessThan('medium')`
    width: 36px;
  `}   
`;

export const ButtonTextContainer = styled.div`
  max-width: 690px;
  margin-left: -1rem;
  margin-right: -1rem;
  ${media.lessThan('large')`
    max-width: 590px;
  `}  
`;

export const Title = styled.h1 `
  font-weight: 700;
  color: var(--text-color);
  line-height: 145%;
  font-size: 2.4rem;
  margin: 0 1rem 1rem 1rem;
  display:flex;
  align-items: flex-start;
  ${media.greaterThan('small')`
    font-size: 3.2rem;
  `}  
  ${media.greaterThan('medium')`
    font-size: 3.8rem;
  `}    
`;

export const Bullets = styled.ul`
  color: var(--text-color);
  margin: var(--space) 1rem;
  li{
    background-repeat: no-repeat;
    background-position: 0 8px;
    background-size: 16px 16px; 
    padding-left: 25px;
    margin: 4px 0;
    ${media.lessThan('large')`
      padding-right: 60px;
      background-position: 0 5px;
      max-width: 450px;
  `}  
  }
`;

export const NavigationButton = styled.button`
  cursor: pointer;
  width: calc(100% - 2rem);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 0 2rem;
  height: 44px;
  border-radius: 22px;
  background: var(--action-color);
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  border: 2px solid var(--action-color);
  &:hover{
    background-color: var(--action-color-hover);
    border-color: var(--action-color-hover);
    color: var(--color-white);
  }


  ${media.greaterThan('medium')`  
    width: auto;
    font-size: 2.2rem;
    height: 68px;
    border-radius: 36px;
    padding: 0 2.4rem;
  `}
`;

export const NavigationButtonSecondary = styled(LocalizedLink)`
  cursor: pointer;
  width: calc(100% - 2rem);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 0 2rem;
  height: 44px;
  border-radius: 22px;
  color: var(--action-color);
  text-decoration: none;
  font-weight: normal;
  text-align: center;
  transition: background-color.3s ease-in-out;
  background-color: transparent;
  border: 2px solid var(--action-color);
  &:hover{
    background-color: var(--action-color);
    border-color: var(--action-color);
    color: var(--color-white);
  }


  ${media.greaterThan('medium')`  
    width: auto;
    font-size: 2.2rem;
    height: 68px;
    border-radius: 36px;
    padding: 0 2.4rem;
  `}
`;

export const NavigationButtonSecondaryExternal = styled.a`
  cursor: pointer;
  width: calc(100% - 2rem);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 1rem;
  padding: 0 2rem;
  height: 44px;
  border-radius: 22px;
  color: var(--action-color);
  text-decoration: none;
  font-weight: normal;
  text-align: center;
  transition: background-color.3s ease-in-out;
  background-color: transparent;
  border: 2px solid var(--action-color);
  &:hover{
    background-color: var(--action-color);
    border-color: var(--action-color);
    color: var(--color-white);
  }

  ${media.greaterThan('medium')`  
    width: auto;
    font-size: 2.2rem;
    height: 68px;
    border-radius: 36px;
    padding: 0 2.4rem;
  `}
`;