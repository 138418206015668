import styled from 'styled-components';
import Img from 'gatsby-image';

export const Item = styled.div`
`;

export const ItemImgWrapper = styled.div`
`;

export const ItemImg = styled(Img)`
`;

export const ItemTitle = styled.h3`
  margin-top: var(--space-sm);
  font-weight: 600;
`;

export const ItemText = styled.p`
  margin-top: var(--space-sm);
  color: var(--text-color);  
`;
