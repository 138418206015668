import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import FeaturedItem from '../components/FeaturedItem';
import SectionItem from '../components/SectionItem';
import SectionItemImage from '../components/SectionItemImage';
import SubTitlePage from '../components/SubTitlePage';
import Banner from '../components/Banner';
import Container from '../components/Container';
import RichText from '../components/RichText';
import useResponsiveImage from '../components/useResponsiveImage';
import {ConvertMarkdownToHtml} from '../shared/helpers';

import * as S from './home-page.styled';

const HomePage = ({ data: { pageData }, pageContext: { locale } }) => {

  const pageContent = pageData.frontmatter;
  const featuredList = pageData.frontmatter.features.items;
  const registerUrl = pageContent.registerUrl
  const bannerContent = {
    "image": pageContent.bannerImage,
    "text": pageContent.title,
    "bullet1": pageContent.bullet1,
    "bullet2": pageContent.bullet2,
    "bullet3": pageContent.bullet3,    
    "buttonText": pageContent.buttonText,
    "buttonUrl": pageContent.buttonUrl,    
  }

  return (
    <>

      <SEO 
        title={pageContent.title} 
        description={`✓ ${pageContent.bullet1} ✓ ${pageContent.bullet2} ✓ ${pageContent.bullet3}`} 
        lang={locale} />
      <Banner bannerContent={bannerContent} lang={locale} registerUrl={registerUrl} />

      <S.SectionGradient>
        <Container>

          <S.LogosText dangerouslySetInnerHTML={{ __html: pageContent.logos.text}}></S.LogosText>

          <S.Logos>
            {pageContent.logos.items.slice(0, 5).map(
              ({
                alt,
                image,
              }, index) => (
                <S.Logo alt={alt} src={image} key={index} width="150px" height="73px"/>

              ),
            )}                
          </S.Logos>

          <S.Tag>{pageContent.introduction.tag} </S.Tag>
          <SubTitlePage text={pageContent.introduction.title} />
          <RichText dangerouslySetInnerHTML={{ __html: ConvertMarkdownToHtml(pageContent.introduction.description) }}/>    
        </Container>

          <S.IntroImage
            fluid={useResponsiveImage(pageContent.introduction.image, false)}
            alt={pageContent.introduction.title}
            
          />    
      </S.SectionGradient>

      {pageContent.section1.isVisible &&
        <S.Section className={pageContent.section1.hasWhiteBackground ? null : 'alt'}> 
          <Container>
            <S.Row>
              <S.Column>
                <S.Tag>{pageContent.section1.tag} </S.Tag>
                <SubTitlePage text={pageContent.section1.title} />
                <RichText dangerouslySetInnerHTML={{ __html: ConvertMarkdownToHtml(pageContent.section1.description) }}/>                 
              </S.Column>
              <S.ColumnWide>
                <S.IntroImage
                  fluid={useResponsiveImage(pageContent.section1.image, false)}
                  alt={pageContent.section3.title}
                    
                  />                
              </S.ColumnWide>

            </S.Row>
            {
              pageContent.section1.items.length > 2 ? 
                <S.Row>
                {pageContent.section1.items.slice(0, 3).map(
                  ({
                    title,
                    text,
                    image,
                  }, index) => (
                    <S.Column key={index}>
                      <SectionItem
                      title={title}
                      text={text}
                      image={image}
                    />                
                    </S.Column>

                  ),
                )}
                </S.Row>           
              : null
            }
          </Container>
        </S.Section>
      }

      {pageContent.features.isVisible && 

      <S.Section className={pageContent.features.hasWhiteBackground ? null : 'alt'}>
        <Container>
          <S.Tag>{pageContent.features.tag} </S.Tag>
          <S.SubTitle>
            {pageContent.features.title} 
          </S.SubTitle>       

          <S.FeaturedWrapper>
          {featuredList.map(
            ({
              title,
              text,
              image,
            }, index) => (
                <FeaturedItem
                title={title}
                text={text}
                image={image}
                key={index}
              />
            ),
          )}
          </S.FeaturedWrapper>
        </Container>
      </S.Section>
      }

      {pageContent.feature2.isVisible &&  

        <S.Section className={pageContent.feature2.hasWhiteBackground ? null : 'alt'}>
          <Container>

            <S.Row>
              <S.ColumnWide>
                <S.Tag>{pageContent.feature2.tag}</S.Tag>
                <SubTitlePage text={pageContent.feature2.title} />
                <RichText dangerouslySetInnerHTML={{ __html: ConvertMarkdownToHtml(pageContent.feature2.description) }}/>                 
              </S.ColumnWide>
    
            </S.Row>
            {
              pageContent.feature2.items.length > 2 ? 
                <S.Row>
                {pageContent.feature2.items.slice(0, 3).map(
                  ({
                    title,
                    text,
                    image,
                  }, index) => (
                    <S.Column key={index}>
                      <SectionItemImage
                      title={title}
                      text={text}
                      image={image}
                    />                
                    </S.Column>
                  ),
                )}
                </S.Row>           
              : null
            }
          </Container>
        </S.Section>

      }

      {pageContent.section2.isVisible &&
        <S.Section className={pageContent.section2.hasWhiteBackground ? null : 'alt'}>
          <Container>
              <S.Row>
              <S.ColumnWide>
                  <S.IntroImage
                      fluid={useResponsiveImage(pageContent.section2.image, false)}
                      alt={pageContent.section2.title}
                      
                    />                
                </S.ColumnWide>              
                <S.Column>
                  <S.Tag>{pageContent.section2.tag} </S.Tag>
                  <SubTitlePage text={pageContent.section2.title} />
                  <RichText dangerouslySetInnerHTML={{ __html: ConvertMarkdownToHtml(pageContent.section2.description) }}/>                 
                </S.Column>
            </S.Row>
            {
              pageContent.section2.items.length > 2 ? 
                <S.Row>
                {pageContent.section2.items.slice(0, 3).map(
                  ({
                    title,
                    text,
                    image,
                  }, index) => (
                    <S.Column key={index}>
                      <SectionItem
                      title={title}
                      text={text}
                      image={image}
                    />                
                    </S.Column>

                  ),
                )}
                </S.Row>           
              : null
            }
          </Container>        
        </S.Section>
      }

      {pageContent.feature1.isVisible &&
        <S.Section className={pageContent.feature1.hasWhiteBackground ? null : 'alt'}>
          <Container>
            <S.Row>
              <S.ColumnWide>
                <S.Tag>{pageContent.feature1.tag} </S.Tag>
                <SubTitlePage text={pageContent.feature1.title} />
                <RichText dangerouslySetInnerHTML={{ __html: ConvertMarkdownToHtml(pageContent.feature1.description) }}/>                 
              </S.ColumnWide>
              <S.Column>
                  <S.IntroImage
                  fluid={useResponsiveImage(pageContent.feature1.image, false)}
                  alt={pageContent.feature1.title}
                  
                />           
              </S.Column>
            </S.Row>
          </Container>
        </S.Section>     
      }

      {pageContent.section3.isVisible &&
        <S.Section className={pageContent.section3.hasWhiteBackground ? null : 'alt'}>
          <Container>

            <S.Row>
              <S.Column>
                <S.Tag>{pageContent.section3.tag} </S.Tag>
                <SubTitlePage text={pageContent.section3.title} />
                <RichText dangerouslySetInnerHTML={{ __html: ConvertMarkdownToHtml(pageContent.section3.description) }}/>                 
              </S.Column>
              <S.ColumnWide>
                  <S.IntroImage
                  fluid={useResponsiveImage(pageContent.section3.image, false)}
                  alt={pageContent.section3.title}
                  
                />           
              </S.ColumnWide>
            </S.Row>

            {
              pageContent.section3.items.length > 2 ? 
                <S.Row>
                {pageContent.section3.items.slice(0, 3).map(
                  ({
                    title,
                    text,
                    image,
                  }, index) => (
                    <S.Column key={index}>
                      <SectionItem
                      title={title}
                      text={text}
                      image={image}
                    />                
                    </S.Column>

                  ),
                )}
                </S.Row>           
              : null
            }

          </Container>
        </S.Section>
     }


    </>
  );
};

export default HomePage;

export const query = graphql`
  query HomePage($locale: String!, $title: String!) {   
    pageData:markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        registerUrl
        title
        bullet1
        bullet2
        bullet3
        bannerImage
        buttonText
        buttonUrl
        logos{
          text
          items{
            alt
            image
          }
        }
        introduction {
          tag
          title
          description
          image
        }
        section1 {
          isVisible
          hasWhiteBackground
          tag
          title
          description
          image
          items {
            title
            text
            image 
          }         
        } 
        section2 {
          isVisible
          hasWhiteBackground
          tag
          title
          description
          image
          items {
            title
            text
            image 
          }         
        }
        section3 {
          isVisible
          hasWhiteBackground
          tag
          title
          description
          image
          items {
            title
            text
            image 
          }         
        }                          
        features {
          isVisible
          hasWhiteBackground
          tag
          title
          items {
            title
            text
            image 
          }
        }
        feature1 {
          isVisible
          hasWhiteBackground
          tag
          title
          description
          image       
        }        
        feature2 {
          isVisible
          hasWhiteBackground
          tag
          title
          description
          items {
            title
            text
            image 
          }         
        }                 
      }
    }      
  }
`;
