import React from "react";
import useTranslations from "../useTranslations";
import { useStaticQuery, graphql } from "gatsby";
import * as S from "./styled";
import useResponsiveImage from "../useResponsiveImage";
import TryNowButton from "../TryNowButton";

const Banner = ({ bannerContent, lang, registerUrl }) => {

  const { iconCheckmark } = useStaticQuery(
    graphql`
      query {
        iconCheckmark: allFile(filter: { name: { eq: "icon-checkmark" } }) {
          edges {
            node {
              publicURL
            }
          }
        }
      }
    `
  );

  const iconCheckmarkUrl = iconCheckmark.edges[0].node.publicURL;
  const compareHref = bannerContent.buttonUrl.toLowerCase();
  const linkIsExternal = bannerContent.buttonUrl ? 
    (compareHref.includes("://") || compareHref.includes("file:") || compareHref.includes("mailto:")) : false;

  return (
    <S.BannerWrapper>
      <S.Container>
        <S.ButtonTextContainer>
          <S.Title>{bannerContent.text}</S.Title>

          <S.Bullets>
            <li style={{ backgroundImage: `url(${iconCheckmarkUrl})` }}>
              {bannerContent.bullet1}
            </li>
            <li style={{ backgroundImage: `url(${iconCheckmarkUrl})` }}>
              {bannerContent.bullet2}
            </li>
            <li style={{ backgroundImage: `url(${iconCheckmarkUrl})` }}>
              {bannerContent.bullet3}
            </li>
          </S.Bullets>

          <TryNowButton lang={lang} large={true} registerUrl={registerUrl}/>
            {
              linkIsExternal ? 
                <S.NavigationButtonSecondaryExternal
                  href={bannerContent.buttonUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {bannerContent.buttonText}
                </S.NavigationButtonSecondaryExternal> 
              : 
                <S.NavigationButtonSecondary
                  to={bannerContent.buttonUrl}
                >
                  {bannerContent.buttonText}
                </S.NavigationButtonSecondary>  
            }

               

 

        </S.ButtonTextContainer>

      </S.Container>

      <S.BackgroundImageWrapper>
        <S.BackgroundImage
          fluid={useResponsiveImage(bannerContent.image, false)}
          alt={bannerContent.text}
        />
      </S.BackgroundImageWrapper>
    </S.BannerWrapper>
  );
};

export default Banner;
