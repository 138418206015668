import styled from 'styled-components';
import media from 'styled-media-query';

export const Item = styled.div`
  display: block;
  margin: var(--space-lg) var(--space-lg) var(--space-lg) 0;
  text-align: center;
  background-color: var(--color-white);
  min-width: 280px;
  padding: var(--space-lg);
  border-radius: 20px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  ${media.greaterThan('large')`
    margin: var(--space-lg);
  `}     
`;

export const ItemImgWrapper = styled.div`
  display:flex;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  justify-content: center;
`;

export const ItemImg = styled.img`
width: 44px;
`;

export const ItemTitle = styled.h3`
  margin-top: var(--space-sm);
  font-weight: 600;
`;

export const ItemText = styled.p`
  margin-top: var(--space-sm);
  color: var(--text-color);  
`;

export const ReadMore = styled.div`
  ${media.greaterThan('small')`
    margin-top: var(--space-sm);
  `}   
`
