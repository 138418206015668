import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image';

export const Section = styled.section`
  // background-color: var(--bg-light);
  // border: 1px solid var(--bg-light);
  &.alt{
    background-color: var(--bg-light);
    border: 1px solid var(--bg-light);
    margin-top: 1px;
  }
`;

export const SectionGradient = styled.section`
  background-image: linear-gradient(var(--color-white), var(--bg-light));
  margin-top: 1px;
`;

export const Row = styled.div`
  ${media.greaterThan('medium')`
    display:flex;
    margin-left: calc(-1 * var(--space-lg));
    margin-right: calc(-1 * var(--space-lg));
    width: 100%;
  `}
`;

export const Column = styled.div`
  margin-top: var(--space-xl);
  margin-bottom: var(--space-xl);
  ${media.greaterThan('medium')`
    width: 33.33333%;
    flex: none;
    padding: 0 var(--space-lg);
  `}
`;

export const ColumnWide = styled.div`
  ${media.greaterThan('medium')`
    flex-grow: 1;
    padding: 0 var(--space-lg);
  `}
`;

export const LogosText = styled.p`
    text-align: center;
    font-weight: 600;
    margin-top: -1em;
    strong {
      color: var(--primary-color);
    }
`;
export const Logos = styled.div`
   display: flex;
   justify-content: space-evenly;
   margin-top: var(--space);
   margin-bottom: var(--space-xl);
   ${media.lessThan('medium')`
    display: block;
    text-align: center;
  `}   
`;
export const Logo = styled.img`
  max-width: 150px;
  filter: grayscale(100%);  
  opacity: 0.8;
  &:hover{
    filter: none;  
  }
  ${media.lessThan('medium')`
    display: inline-block;
    max-width: 100px;
  `}     
`;

export const IntroImage = styled(Img)`
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  margin-top: calc(-1 * var(--space-lg));
`;

export const FeaturedWrapper = styled.section`
  display: flex;
  overflow: auto;

  ${media.greaterThan('large')`
   margin: calc(-1 * var(--space-lg));
    display: grid;
    grid-template-columns:  repeat(3, 1fr);
  `}
`

export const SubTitle = styled.h2`
  line-height: 145%;
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: 1em;
`;
export const Tag = styled.div`
  line-height: 145%;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0;
  color: var(--action-color);
  ${media.lessThan('medium')`
    font-size: 14px;
`}  
`;

