import React from 'react';
import PropTypes from 'prop-types';
import RichText from '../RichText';
import {ConvertMarkdownToHtml} from '../../shared/helpers';
import * as S from './styled';

const SectionItem = ({
  image,
  title,
  text,
}) => {

  return (
    <S.Item>
      <S.ItemImgWrapper>

      <S.ItemImg
        src={image}
        alt={title}
        width="44px" height="44px"
      />
      
      </S.ItemImgWrapper>

      <S.ItemTitle>
        {title}
      </S.ItemTitle>

      <S.ItemText>
        <RichText dangerouslySetInnerHTML={{ __html: ConvertMarkdownToHtml(text) }} />
      </S.ItemText>

    </S.Item>
  );
};

SectionItem.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SectionItem;
