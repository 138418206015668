import React from 'react';
import PropTypes from 'prop-types';
import useResponsiveImage from '../useResponsiveImage';
import * as S from './styled';
import RichText from '../RichText';
import {ConvertMarkdownToHtml} from '../../shared/helpers';

const SectionItemImage = ({
  image,
  title,
  text,
}) => {

  return (
    <S.Item>

      <S.ItemImgWrapper>

      <S.ItemImg
        fluid={useResponsiveImage(image, false)}
        alt={title}
      />
      
      </S.ItemImgWrapper>

      <S.ItemTitle>
        {title}
      </S.ItemTitle>

      <S.ItemText>
        <RichText dangerouslySetInnerHTML={{ __html: ConvertMarkdownToHtml(text) }}/> 
      </S.ItemText>

    </S.Item>
  );
};

SectionItemImage.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SectionItemImage;
